import React, { useState, useRef } from "react"
import Slider from "react-slick"
import PageSectionHeader from "../../common/PageSectionHeader"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const KeyFeatures = ({subTitle, title, tabs}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id)
  const sliderRef = useRef(null)
  const settings = {
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
    afterChange: currentSlide => {
      setActiveTab(`tab${currentSlide + 1}`)
    },
  }

  const goToCenterSlide = index => {
    sliderRef.current.slickGoTo(index)
    setActiveTab("tab" + index)
  }

  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={subTitle}
        title={title}
        titleEC={`!max-w-[1100px]`}
      />
      <div className="key-features--slider mt-8">
        <Slider ref={sliderRef} {...settings}>
          {tabs.map((tab, i) => (
            <div className="text-center p-4" key={tab.id}>
              <button
                className={`py-2 sm:py-3 px-2 rounded-[50px] text-[14px] lg:text-[18px] font-[500] border-2 border-[#0A1E46] transition duration-300 w-full ${
                  activeTab === tab.id
                    ? `bg-[#0A1E46] text-white tab-button-active-after`
                    : `text-[#0A1E46]`
                }`}
                onClick={() => goToCenterSlide(i)}
              >
                {tab.label}
              </button>
            </div>
          ))}
        </Slider>

        <div className="mt-4">
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={`mt-4 ${activeTab === tab.id ? "block" : "hidden"}`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default KeyFeatures
