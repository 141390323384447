// Keys Features
import React from "react"
import { faRocket } from "@fortawesome/free-solid-svg-icons"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faSun } from "@fortawesome/free-solid-svg-icons"
import CTASectionWithImage from "../components/molecules/CTASectionWithImage"
import { featuresPostTypeSlug } from "./Global"

// Hero Section
export const heroSection = {
  heroTitleFirstRowText: `Practice Management `,
  heroTitleSecondRowText: `Software For `,
  heroTitleThirdRowText: `Accountants`,
  brushImage: {
    path: "heading-below-brushline",
    alt: "heading-below-brushline",
  },
  description: `Introducing Synkli, an intuitive management tool for accountants. It is designed to digitally collect and manage financial data from your clients for the best accounting practice management.`,
  btns: [
    {
      to: "https://partners.synkli.com.au/signup/",
      href: "https://partners.synkli.com.au/signup/",
      text: "Get started",
      ec: "primary-btn",
    },
    {
      to: featuresPostTypeSlug,
      href: "",
      text: "Learn More",
      ec: "primary-transparent-btn",
    },
  ],
}

// Hero Animation Section
export const HeroSectionAnimationItems = [
  {
    name: "speed1",
    items: [
      {
        peopleEC: "set1a",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
        imageBottom: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
      },
      {
        peopleEC: "set1b",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
        imageBottom: {
          path: "home-herosection--animated-image-4",
          alt: "home-herosection--animated-image-4",
        },
      },
      {
        peopleEC: "set1c",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-5",
          alt: "home-herosection--animated-image-5",
        },
        imageBottom: {
          path: "home-herosection--animated-image-6",
          alt: "home-herosection--animated-image-6",
        },
      },
      {
        peopleEC: "set1d",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-7",
          alt: "home-herosection--animated-image-7",
        },
        imageBottom: {
          path: "home-herosection--animated-image-8",
          alt: "home-herosection--animated-image-8",
        },
      },
      {
        peopleEC: "set1e",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-9",
          alt: "home-herosection--animated-image-9",
        },
        imageBottom: {
          path: "home-herosection--animated-image-10",
          alt: "home-herosection--animated-image-10",
        },
      },
      {
        peopleEC: "set1f",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-11",
          alt: "home-herosection--animated-image-11",
        },
        imageBottom: {
          path: "home-herosection--animated-image-12",
          alt: "home-herosection--animated-image-12",
        },
      },
    ],
  },
  {
    name: "speed2",
    items: [
      {
        peopleEC: "set4a",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-13",
          alt: "home-herosection--animated-image-13",
        },
        imageBottom: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
      },
      {
        peopleEC: "set4b",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
        imageBottom: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
      },
      {
        peopleEC: "set4c",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
        imageBottom: {
          path: "home-herosection--animated-image-4",
          alt: "home-herosection--animated-image-4",
        },
      },
    ],
  },
  {
    name: "speed3",
    items: [
      {
        peopleEC: "set3a",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-5",
          alt: "home-herosection--animated-image-5",
        },
        imageBottom: {
          path: "home-herosection--animated-image-12",
          alt: "home-herosection--animated-image-12",
        },
      },
      {
        peopleEC: "set3b",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-13",
          alt: "home-herosection--animated-image-13",
        },
        imageBottom: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
      },
      {
        peopleEC: "set3c",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
        imageBottom: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
      },
    ],
  },
]

// HeroSectionAnimationAfterWidget Section
export const heroSectionAnimationAfterWidget = {
  image: {
    path: `homepage--widget`,
    alt: `homepage--widget`,
  },
}

// Pricing Section
export const pricingSection = {
  subTitle: `Pricing`,
  title: `Pricing Plans`,
  description: `Simplify Your Choices, Clear Pricing Plans Tailored to Your Needs`,
  percentageOffText: `<span className="text-[#B695F8]">20%</span> OFF ALL YEAR`,
  pricingArrowIcon: {
    path: `home-pricing--arrow-icon`,
    alt: `home-pricing--arrow-icon`,
  },
  plans: [
    {
      tagLine: `Easy Individual`,
      cardIcon: faRocket,
      title: `Salary Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `9.99`,
      yearlyPrice: `95.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Product" },
        { text: "Support" },
        { text: "Access for all tools" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons: [{ text: `Plus $5 - With Trip` }],
      annual_addons: [{ text: `Plus $48 - With Trip` }],
    },
    {
      tagLine: `Recommended for you`,
      cardIcon: faHeart,
      title: `Business Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `9.99`,
      yearlyPrice: `95.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Support" },
        { text: "Access for all tools" },
        { text: "Single user Access" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons: [{ text: `Plus $5 - With Trip` }],
      annual_addons: [{ text: `Plus $48 - With Trip` }],
    },
    {
      tagLine: `For Larger Tearms`,
      cardIcon: faSun,
      title: `Rental Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `14.99`,
      yearlyPrice: `143.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Product" },
        { text: "Support" },
        { text: "Access for all tools" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons: [
        { text: `Plus $6 - Rental Samurai with 3 Properties` },
        { text: `Plus $15 - Property Boss with 7 Properties` },
        { text: `Plus $30 - Estate Elite with 15 Properties` },
      ],
      annual_addons: [
        { text: `Plus $57 - Rental Samurai with 3 Properties` },
        { text: `Plus $144 - Property Boss with 7 Properties` },
        { text: `Plus $288 - Estate Elite with 15 Properties` },
      ],
    },
  ],
}

// Key Feautures Section
export const keyFeaturesSection = {
  subTitle: `FEATURES`,
  title: `Key Features: Trusted Financial Data Sharing Toolkit`,
  tabs: [
    {
      id: "tab1",
      label: "Document Hub",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Document Hub"}
          description={`Document Hub, a file manager, simplifies accounting workflows by organising and storing client financial documents, including invoices and tax returns, in a single, secure location. Businesses and accountants can easily upload, review, and share files, facilitating collaboration and enhancing efficiency. `}
          buttonn={{
            to: featuresPostTypeSlug + "document-hub/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "features-page--document-hub",
            alt: "features-page--document-hub",
          }}
        />
      ),
    },
    {
      id: "tab2",
      label: "Online Signature",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Online Signature"}
          description={`Say goodbye to paper trails with Synkli's online signature feature. Sign documents online quickly and securely, saving you time and effort. Our digital signature feature ensures your documents are legally binding and safely stored, making document management more efficient.`}
          buttonn={{
            to: featuresPostTypeSlug + 'online-signature/',
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--online-signature",
            alt: "feature--online-signature",
          }}
        />
      ),
    },
    {
      id: "tab3",
      label: "Easy communication feature",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Easy communication feature"}
          description={`Efficiently communicate with your team members and accountants through our integrated chatbox. Share updates, discuss tasks, and collaborate seamlessly within the platform. Instant messaging ensures quick responses and keeps everyone aligned, enhancing productivity and teamwork.`}
          buttonn={{
            to: featuresPostTypeSlug,
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--easy-communication-feature",
            alt: "feature--easy-communication-feature",
          }}
        />
      ),
    },
    {
      id: "tab4",
      label: "Expense and income management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Expense and income management"}
          description={`Keep track of your business expenses and income effortlessly. Our intuitive interface helps you monitor and manage your cash flow with ease. Generate detailed reports, set budgets, and gain insights into your financial health, ensuring better financial planning.`}
          buttonn={{
            to: featuresPostTypeSlug + 'expense-and-income-management/',
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--expense-and-income-management",
            alt: "feature--expense-and-income-management",
          }}
        />
      ),
    },
    {
      id: "tab5",
      label: "Mileage Tracker",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Mileage Tracker"}
          description={`Days of manually tracking your trips are over with our mileage tracker feature. You can automatically track your mileage for business and personal trips. Log and report your travel easily to ensure you get accurate deductions claims.`}
          buttonn={{
            to: featuresPostTypeSlug + "mileage-tracking/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--mileage-tracker",
            alt: "feature--mileage-tracker",
          }}
        />
      ),
    },
    {
      id: "tab6",
      label: "Team Management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Team Management"}
          description={`Synkli has a feature that lets you have all your team members in a place. You can assign roles, track progress, and ensure everyone is on the same page. This improves collaboration and productivity by managing your team's workload and communication in one centralised platform.`}
          buttonn={{
            to: featuresPostTypeSlug + "team-management/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--team-managment",
            alt: "feature--team-managment",
          }}
        />
      ),
    },
    {
      id: "tab7",
      label: "Efficient Task Management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Efficient Task Management"}
          description={`Stay on top of your to-do list with our task management feature. Create, assign, and track tasks for your team members to ensure nothing falls through the cracks. Prioritise and manage your clients ' deadlines effortlessly, keeping your team organised and projects on track.`}
          buttonn={{
            to: featuresPostTypeSlug + "task-management-and-reporting/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--task-management",
            alt: "feature--task-management",
          }}
        />
      ),
    },
  ],
}

// Use Cases Section
export const useCasesSection = {
  subTitle: `Use Cases`,
  title: `End Users of Synkli`,
  useCases: [
    {
      title: `Businesses and Individuals`,
      description: `Synkli meets the specific needs of business owners and individuals by simplifying data-sharing processes and providing real-time financial insights. Automating manual tasks and centralising financial data allows businesses and individuals to digitally optimise their data sharing with accountants, promptly make informed, data-driven decisions, and seek tax benefits.`,
      image: {
        path: `end-users-of-synkli--businesses-and-individuals`,
        alt: `end-users-of-synkli--businesses-and-individuals`,
      },
    },
    {
      title: `Accountants and Bookkeepers`,
      description: `Our software equips accountants with advanced tools for managing client financial data and collaboration. With secure data collection, team management, and detailed reporting, Synkli enhances accountants' practice management. It offers diverse digital solutions to clients, such as paperless sharing, saving time and effort.`,
      image: {
        path: `end-users-of-synkli--accountants-and-bookkeepers`,
        alt: `end-users-of -synkli--accountants-and-bookkeepers`,
      },
    },
  ],
}

// Testimonials Section
export const testimonialSection = {
  title: `What our clients are saying`,
  description: `Clients are best at sharing their opinions and testimonials with others. Hear first-hand testimonies from satisfied clients on how we have addressed their financial data-sharing and management concerns. Learn how we transform data management for businesses and individuals, as well as accountants' practice management.`,
}

// MobileAppLinks Section
export const mobileAppLinksSection = {
  title: `Maximise Your Financial Goals With Synkli`,
  description: `Download our mobile app for accurate data sharing and management, designed to help you maximise tax benefits. With Synkli, you can explore various financial data-gathering tools and features.`,
}

// Happy Customer Section
export const happyCustomerSection = {
  subTitle: `Happy Customer`,
  mainTitle: `A Day in The Life of a Happy Client`,
  title: `Using a single software solution that handles multiple functions is genuinely remarkable.`,
  description: `“Synkli has been a game changer as it helps our accounting firm manage our clients' accounting needs simultaneously. We now know how to make businesses that work with us organise their financial summaries, simplifying workflows for all parties involved.”`,
}

// Faqs Section
export const faqs = [
  {
    question:
      "Is the app the same for accountants and businesses, or are they different?",
    answer: `There are different apps for businesses and accountants. The mobile app is only for businesses or individuals, while the website is only for accountants.`,
  },
  {
    question: "How can Synkli benefit my business?",
    answer: `Businesses always need help dealing with financial data sharing and management. However, with Synkli, you can easily record your data and claim eligible business deductions through a receipt scan using the mobile app. Lastly, you have also access to your accountants on Synkli.`,
  },
  {
    question:
      "Does Synkli support integration with other accounting tools or software?",
    answer: `Yes. Accountants can sync their data with XERO and Outlook. It’s a one-way communication, and the syncing process is easy.`,
  },
  {
    question: "Can I use Synkli for free?",
    answer: `The app has free and premium versions. However, to enjoy all of its excellent benefits, you will need to subscribe. Luckily, Synkli offers a 30-day free trial to both accountants and businesses/individuals, so you can explore all these features and see how they improve your financial workflows and management.`,
  },
  {
    question: "Can I access my data offline?",
    answer: `No, you can't access your data without an internet connection. You need to be online to use Synkli.`,
  },
  {
    question: "Does Synkli support two-factor authentication?",
    answer: `Yes, Synkli requires all users to have two-factor authentication (2FA) enabled. This means you need to confirm your identity in two ways, like using your password and a code sent to your phone, to log in.`,
  },
  {
    question: "Can I use the website instead of the app?",
    answer: `The website is only for accountants right now. If you're a business or an individual, you need to use the mobile app.`,
  },
  {
    question:
      "What if I’m an accountant and a business owner, must I use the two?",
    answer: `Yes, you will need to create two separate accounts. One account will be on the website for your accountant role, and the other will be on the mobile app for your business role.`,
  },
  {
    question: "Is the mileage tracker feature for businesses alone?",
    answer: `No, the mileage tracker can be used by both businesses and individuals. It's available to everyone on the app.`,
  },
  {
    question: "Can bookkeepers use the app too?",
    answer: `Yes, bookkeepers can use the same app that accountants use. They don't need a separate app.`,
  },
  {
    question: "How does it help me claim my deductions?",
    answer: `The app has a "scan and go" feature. You can quickly scan your receipts or invoices with your phone. The app will automatically read the information using OCR (Optical Character Recognition) technology. It then helps you organise and store these records, making it easier to track and claim deductions.`,
  },
  {
    question:
      "What training or support is available to help our team become familiar with the software?",
    answer: `The Synkli team offers a one-on-one session to all accountants to prepare the app for use in the firm. Once an accountant purchases a subscription, one of our team members will contact you to continue the process.`,
  },
  // More questions...
]
